import React, { useCallback } from 'react';

import { FaWhatsapp, FaGoogle, FaTrophy, FaHandsHelping, FaBuilding } from 'react-icons/fa';

// Illustrations
import FoodIllustration from '../../assets/img/illustrations/alimentos.svg';
import BarCodeIllustration from '../../assets/img/illustrations/barcode.svg';
import BarCodeIllustration2 from '../../assets/img/illustrations/barcode2.svg';
import HospitalIllustration from '../../assets/img/illustrations/hospitalar.svg';
import LogisticIllustration from '../../assets/img/illustrations/logistica.svg';
import TimeImg from '../../assets/img/photos/TimeImg.jpg';
import Logo from '../../assets/img/illustrations/logo.svg';
import {useHistory} from 'react-router';

//carousel
import {Carousel} from 'react-responsive-carousel';

// Covers
import FormCover from '../../assets/img/covers/coverAc.jpg';

//Modal
import ModalVideo from '../../components/Modal/modal';

//Depoimentos
import Depo1 from '../../assets/img/assessments/1.png';
import Depo2 from '../../assets/img/assessments/2.png';
import Depo3 from '../../assets/img/assessments/3.png';
import Depo4 from '../../assets/img/assessments/4.png';
import Depo5 from '../../assets/img/assessments/5.png';
import Depo6 from '../../assets/img/assessments/6.png';
import Depo7 from '../../assets/img/assessments/7.png';
import Depo8 from '../../assets/img/assessments/8.png';
import Depo9 from '../../assets/img/assessments/9.png';
import Depo10 from '../../assets/img/assessments/10.png';



// Social Media
import Facebook from '../../assets/img/illustrations/facebook.svg';
import Instagram from '../../assets/img/illustrations/instagram.svg';
import Youtube from '../../assets/img/illustrations/youtube.svg';
import Globe from '../../assets/img/illustrations/globe.svg';

import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import 'bootstrap/dist/css/bootstrap.min.css';

const Welcome: React.FC = () => {
  const history = useHistory();

  const SendForm = useCallback(() => {
    history.push('/obrigado');
  }, []);
  
  return (
    <>
    <section className="border-bottom">
      <div className="container">
        <div className=" row align-items-stretch">
          <div className=" d-none d-sm-block col-4 col-md-5 offset-md-1 order-md-2">

            

            <div className="position-relative h-100 vw-50 d-none d-md-block" data-aos="fade-left">

              <div className="flickity-button-bottom flickity-button-white h-100 w-100" data-flickity='{"imagesLoaded": true, "setGallerySize": false, "wrapAround": true, "pageDots": false}'>
                <div className="w-100 h-100 bg-cover" style={{backgroundImage: `url(${FormCover})`}}></div>
              </div>
              
              

            </div>

          </div>
          <div className="col-12 col-md-6 py-4 py-md-4 order-md-1 text-center" data-aos="fade-right">
            <img src={Logo} alt="..." className="img-fluid" style={{marginBottom: '30px', width:'400px'}} />

            <h1 className="display-5">
            Reduza em até <span className="text-danger">30%</span> seu custo atráves da identificação 
            </h1>

            <p className="lead text-muted mb-2 mb-md-2">
            Nossas soluções otimizam processos com <span className="text-danger">eficiência</span>, garantindo <span className="text-danger">resultados rápidos</span> e precisos para o seu negócio.
            </p>
            
            <div className='pb-5 col-12 col-md-12 pt-5 d-flex justify-content-center'>
                <a 
                    href='https://api.whatsapp.com/send/?phone=554132561011&text=Olá, tudo bem? Preciso de uma cotação!! Gostaria de mais informações sobre Impressoras, Etiquetas e Ribbons.&app_absent=0' 
                    className="btn btn-primary btn-lg shadow lift text-white" 
                    style={{ background: '#25d366', fontSize: '25px', padding: '20px 40px' }}
                >
                    <FaWhatsapp className='IoLogoWhatsapp' /> Faça um orçamento
                </a>
            </div>
            <div className=" embed-responsive embed-responsive-16by9">
                <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/QoZ--Cagv5w" allowfullscreen></iframe>
            </div>
            
          </div>
        </div> 
      </div> 
      
      
    </section>
    

    <section className="py-8 py-md-11 ">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 text-center">

            <h6 className="text-uppercase text-gray-500 fw-bold">
              {/* Seamless integration */}
            </h6>

            <h1 className="fw-bold">
            Solução em Etiquetas Adesivas e Automação Comercial, tudo em um só lugar!
            </h1>

            <p className="fs-lg text-muted mb-7 mb-md-9">
            Temos as melhores soluções em Etiquetas adesivas e Ribbons, Impressoras, Leitores, Coletores de Dados e muito mais,
            atendendo com qualidade a demanda da sua empresa!
            </p>
            <div  className='pb-5 col-12'>
              <ModalVideo/>
            </div>
          </div>
        </div> 
        <div className="row gx-0 mb-7 mb-md-9">
          <div className="col-12 col-md-4 text-center">

            <div className="row mb-5">
              <div className="col">


              </div>
              <div className="col-auto">

                <div className="icon text-danger mb-3">
                  <FaTrophy />
                </div>

              </div>
              <div className="col">

                <hr className="d-none d-md-block" />

              </div>
            </div> 

            <h3 className="fw-bold">
            Fabricação em tempo recorde garantindo sempre os suprimentos para que sua empresa não pare!!!
            </h3>

            <p className="text-muted mb-6 mb-md-0">
              {/* Our sign up is dead simple. We only require your basic company information and what type of data storage you want. */}
            </p>

          </div>
          <div className="col-12 col-md-4 text-center">

            <div className="row mb-5">
              <div className="col">

                <hr className="d-none d-md-block" />

              </div>
              <div className="col-auto">

                <div className="icon text-danger mb-3">
                  <FaHandsHelping />
                </div>

              </div>
              <div className="col">

                <hr className="d-none d-md-block" />

              </div>
            </div> 

            <h3 className="fw-bold">
            Há 12 anos no mercado <br/>
            <span>mais de 10.000 clientes atendidos</span>
            </h3>

            <p className="text-muted mb-6 mb-md-0">
              {/* We support bulk uploading via SQL, integrations with most data storage products, or you can use our API. */}
            </p>

          </div>
          <div className="col-12 col-md-4 text-center">

            <div className="row mb-5">
              <div className="col">

                <hr className="d-none d-md-block" />

              </div>
              <div className="col-auto">

                <div className="icon text-danger mb-3">
                  <FaBuilding />
                </div>

              </div>
              <div className="col">


              </div>
            </div> 

            <h3 className="fw-bold">
              Ampla variedade de etiquetas adesivas e Ribbons disponíveis para envio em todo o Brasil, facilitando sua busca por soluções.
            </h3>
            
            <p className="text-muted mb-0">
              {/* Simply select where you'd like to transfer your data and we'll being the process of migrating it instantly. */}
            </p>

          </div>
          
            <div className="col-12 col-md-12 py-8 py-md-14 order-md-1" data-aos="fade-right">
              <div className='text-center'>
                <h1 className="display-12">
                  Quer saber mais sobre etiquetas? 
                </h1>
                <h1 className="display-12">
                  <span className="text-danger">Fale com um de nossos consultores</span>
                </h1>
                <p className="display-12 text-muted">
                  Automatize seus setores com nossos produtos
                  através da identificação automática e captura de dados.      
                </p>
              
              </div>
              
              <div className='col-md-6 offset-md-3'>
                <a href='https://api.whatsapp.com/send/?phone=554132561011&text=Olá, tudo bem? Preciso de uma cotação!!, ! Gostaria de mais informações sobre Impressoras, Etiquetas e Ribbons.&app_absent=0' className="col-12 btn btn-primary btn-lg shadow lift text-white" style={{background:'#25d366', fontSize: 35 }}>
                
                <FaWhatsapp className='IoLogoWhatsapp' /> Faça um orçamento
                </a>
              </div>

            </div>
          
        </div>
      </div>   
        
    </section>

    <section className="bg-gray-300 ">
      <div className="container">
        <div className="pb-5 pt-5 row justify-items-center">
          <div className="col-12 col-md-5 col-lg-5 order-md-2">

            <img src={BarCodeIllustration2} alt="..." className="img-fluid mb-4 mb-md-0" />

          </div>
          <div className="col-12 col-md-7 col-lg-7 order-md-1">

            <h2>
              Sobre
              <span className="text-danger"> Nós</span>.
            </h2>

            <p className="fs-lg text-gray-700 mb-6 text-center">
            Somos uma empresa focada em soluções sob medida para sua empresa, em identificação e
            captura de dados, oferecendo uma ampla gama de equipamentos, sendo fabricantes de
            suprimentos em etiquetas adesivas e ribbons para diferentes segmentos como: logística, alimentos,
            lojas, área da saúde e muito mais. Buscamos sempre a total satisfação de nossos clientes,
            com resultados completos, priorizando sempre a
            <span className="text-primary"> qualidade </span>
              e
            <span className="text-primary"> agilidade!</span>

            </p>

          </div>
        </div> 
        
      </div> 
      
      
    </section>
    <section className="border-bottom bg-gray-300"    >
        <div className="container">
            <div className="pb-5 pt-5 row justify-items-center">
                
            <div className="col-12 col-md-5 col-lg-5 order-md-1">

                <img src={TimeImg} alt="..." className="img-fluid mb-6 mb-md-0" />

            </div>
            <div className="col-12 col-md-6 col-lg-6 order-md-2">

                <h2>
                    Nossos <span className="text-danger">TALENTOS</span> não são apenas colaboradores...
                </h2>
                <p className="fs-lg text-gray-700 mb-6 text-justify">
                São os <span className="text-danger"> #idprozeiros</span> que demonstram um verdadeiro senso de propriedade, agindo como verdadeiros donos. Eles possuem uma visão empreendedora e são uma fonte de orgulho para todos nós, pois foram habilmente preparados por Deus.
                

                </p>

            </div>
            </div>
        
        </div> 

    </section>



    <section className="pt-8 pt-md-10" id="about">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 text-center">

            <h2 className="fw-bold ">
              Conheça nossos <span className="text-danger">produtos</span>.
            </h2>

            <p className="fs-lg text-muted mb-9">
              {/* Landkit is where your team can come together to get stuff done. Chat, files, wikis, documentation, and more. */}
            </p>

          </div>
        </div> 
        <div className="row">
          <div className="col-12 col-lg-6">

            <div className="row align-items-center mb-8" data-aos="fade-up">
              <div className="col-4 col-lg-5 ">

                <img src={BarCodeIllustration} alt="..." className="img-fluid" />

              </div>
              <div className="col-8 col-lg-7 ">

                <h3 className="fw-bold mb-2">
                  AIDC
                </h3>

                <p className="text-gray-700 mb-0">
                Soluções em impressoras térmicas de código de barras, impressoras não fiscais, leitores de
                código de barras e coletor de dados.
                </p>

              </div>
            </div> 

            <div className="row align-items-center mb-8" data-aos="fade-up">
              
              <div className="col-8 col-lg-7 order-md-2">

                <h3 className="fw-bold mb-2">
                Etiquetas hospitalar
                </h3>

                <p className="text-gray-700 mb-0">
                Identificação de produtos, equipamentos, medicações e até mesmo
                de pacientes dentro de um hospital, consultório, clínica entre outros.
                </p>

              </div>
              <div className="col-4 col-lg-5 order-md-1">

                <img src={HospitalIllustration} alt="..." className="img-fluid" />

              </div>
            </div> 


          </div>
          <div className="col-12 col-lg-6">
            <div className="row align-items-center mb-8" data-aos="fade-up">
              <div className="col-4 col-lg-5">

                <img src={LogisticIllustration} alt="..." className="img-fluid" />

              </div>
              <div className="col-8 col-lg-7">

                <h3 className="fw-bold mb-2">
                Etiquetas Logística
                </h3>

                <p className="text-gray-700 mb-0">
                Ajudam na identificação de caixas para transporte e
                demonstram as informações referentes aos produtos, lote, peso e outros.
                </p>

              </div>
            </div> 

            <div className="row align-items-center mb-8" data-aos="fade-up">
              
              <div className="col-8 col-lg-7 order-md-2">

                <h3 className="fw-bold mb-2">
                Etiquetas para alimentos
                </h3>

                <p className="text-gray-700 mb-0">
                São importantes para que os consumidores conheçam todos os
                componentes que fazem parte do alimento. Trabalhamos também com etiquetas para
                congelados.
                </p>

              </div>
              <div className="col-4 col-lg-5 order-md-1">

                <img src={FoodIllustration} alt="..." className="img-fluid" />

              </div>
            </div> 

          </div>
        </div> 
        <div className='pb-5 row'>
        <div className='col-md-6 offset-md-3'>
          <a href='https://api.whatsapp.com/send/?phone=554132561011&text=Olá, tudo bem? Preciso de uma cotação!!, ! Gostaria de mais informações sobre Impressoras, Etiquetas e Ribbons.&app_absent=0' className="col-12 btn btn-primary btn-lg shadow lift text-white" style={{background:'#25d366', fontSize: 25 }}>
            <FaWhatsapp className='IoLogoWhatsapp' /> Faça um orçamento
          </a>
        </div>
      </div>
      </div> 
    </section>
    


    <section className="border-bottom  " style={{background: '#37474f'}} >
      <div className="container col-md-8 offiset-md-4 d-sm-block " >
     
        <div className='text-center '>
          <h2 className="pb-5 col-md-12 offiset-md-3 text-white pt-4">
            Depoimentos de alguns de nossos clientes:
          </h2>
        </div>
        <Carousel >
            <img src={Depo1}   alt='imagem' title='imagem' />
            <img src={Depo2}   alt='imagem' title='imagem' />
            <img src={Depo3}   alt='imagem' title='imagem' />
            <img src={Depo4}   alt='imagem' title='imagem' />
            <img src={Depo5}   alt='imagem' title='imagem' />
            <img src={Depo6}   alt='imagem' title='imagem' />
            <img src={Depo7}   alt='imagem' title='imagem' />
            <img src={Depo8}   alt='imagem' title='imagem' />
            <img src={Depo9}   alt='imagem' title='imagem' />
            <img src={Depo10}   alt='imagem' title='imagem' />
        </Carousel>
        <div className='pb-5 row'>
        <div className='col-md-6 offset-md-3'>
          <a href='https://maps.app.goo.gl/zGFmJxfvyqhnN9uX6' target="_blank" className="col-12 btn btn-primary btn-lg shadow lift text-white" style={{ fontSize: 20 }}>
          <div style={{ display: 'grid', placeItems: 'center' }}>
            <FaGoogle className='IoLogoWhatsapp' style={{fontSize: 40 }}/> Automação Curitiba no Google
          </div>
          </a>
        </div>
      </div>
      </div>
      
    </section>  
    <section className="border-bottom">
      <div className="container">
        <div className="row align-items-stretch">
          <div className="col-12 col-md-12 py-8 py-md-14 order-md-1" data-aos="fade-right">
            <div className='text-center'>
              <h1 className="display-12">
                Quer saber mais sobre etiquetas? 
              </h1>
              <h1 className="display-12">
                <span className="text-danger">Fale com um de nossos consultores</span>
              </h1>
              <p className="display-12 text-muted">
                Automatize seus setores com nossos produtos
                através da identificação automática e captura de dados.      
              </p>
            
            </div>
            
            <div className='col-md-6 offset-md-3'>
              <a href='https://api.whatsapp.com/send/?phone=554132561011&text=Olá, tudo bem? Preciso de uma cotação!!, ! Gostaria de mais informações sobre Impressoras, Etiquetas e Ribbons.&app_absent=0' className="col-12 btn btn-primary btn-lg shadow lift text-white" style={{background:'#25d366', fontSize: 35 }}>
              
              <FaWhatsapp className='IoLogoWhatsapp' /> Faça um orçamento
              </a>
            </div>

          </div>
        </div> 
      </div> 
    </section>
    

    <footer className="py-8 py-md-11 bg-gray-200">
      <div className="container">
        <div className="col-md-8 offset-md-2">
          <div className='col-md-6 offset-md-3 ' >
            
              <img src={Logo} alt="..." className="footer-brand img-fluid mb-2" />
        
            <p className="text-gray-700 mb-2 text-center">
            Automação Curitiba
            </p>
            <div className='d-flex justify-content-center'>
              <ul className="list-unstyled list-inline list-social mb-6 mb-md-0 " >
                <li className="list-inline-item list-social-item me-3">
                  <a href="https://www.instagram.com/automacao_curitiba/" target='_blank' className="text-decoration-none">
                    <img src={Instagram} className="list-social-icon" alt="..." />
                  </a>
                </li>
                <li className="list-inline-item list-social-item me-3" style={{marginLeft: 15}}>
                  <a href="https://www.facebook.com/automacaocuritiba" target='_blank' className="text-decoration-none">
                    <img src={Facebook} className="list-social-icon" alt="..." />
                  </a>
                </li>
                <li className="list-inline-item list-social-item me-3" style={{marginLeft: 15}}>
                  <a href="https://www.youtube.com/c/AutomacaocuritibaBra" target='_blank' className="text-decoration-none">
                    <img src={Youtube} className="list-social-icon" alt="..." />
                  </a>
                </li>
                <li className="list-inline-item list-social-item">
                  <a href="https://www.automacaocuritiba.com.br/" target='_blank' className="text-decoration-none">
                    <img src={Globe} className="list-social-icon" alt="..." style={{marginLeft: 15}} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div> 
    </footer>

    
      
    </>
  );
};

export default Welcome;
