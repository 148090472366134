import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { FaPlayCircle,  } from 'react-icons/fa';
function Example() {
  const [show, setShow] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="danger" size="lg" onClick={handleShow}>
        <FaPlayCircle/> Assista o Vídeo 
      </Button>

      <Modal show={show} size="lg" onHide={handleClose}>
         
        <div className="embed-responsive embed-responsive-16by9">
            <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/QoZ--Cagv5w" allowfullscreen></iframe>
        </div>
         
      </Modal>
    </>
  );
}

export default Example;