import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Welcome from '../pages/Welcome';
import ConfirmAccount from '../pages/ConfirmAccount';
import ThankYou from '../pages/ThankYou';
import Privacy from '../pages/Privacy';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Welcome} />
    <Route path="/confirmar-conta" component={ConfirmAccount} />
    <Route path="/obrigado" component={ThankYou} />
    <Route path="/politica-de-privacidade" component={Privacy} />
  </Switch>
);

export default Routes;
